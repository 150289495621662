import { MenuItemsType, DropdownMenuItemType, menuStatus } from '@pancakeswap/uikit'
import { ContextApi } from 'contexts/Localization/types'
import { nftsBaseUrl } from 'views/Nft/market/constants'

export type ConfigMenuItemsType = MenuItemsType & { hideSubNav?: boolean }

const config: (t: ContextApi['t']) => ConfigMenuItemsType[] = (t) => [
  {
    label: t('Referral'),
    href: '/',
    icon: 'Community',
    showItemsOnMobile: false,
    items: [],
  },
  {
    label: t('Trade'),
    icon: 'Swap',
    href: '/trade',
    showItemsOnMobile: false,
    items: [],
  },
  // {
  //   label: t('Buy'),
  //   icon: 'Pool',
  //   href: 'https://poocoin.app/tokens/0x0000000000163f154baF9024Ea7Fdec825D12B58',
  //   showItemsOnMobile: false,
  //   externalLink: true,
  //   items: [],
  // },
  // {
  //   label: t('Trade'),
  //   icon: 'Swap',
  //   href: '/swap?outputCurrency=0x55d398326f99059ff775485246999027b3197955',
  //   showItemsOnMobile: false,
  //   items: [
  //     {
  //       label: t('Exchange'),
  //       href: '/swap?outputCurrency=0x55d398326f99059ff775485246999027b3197955',
  //     },
  //     {
  //       label: t('Liquidity'),
  //       href: '/liquidity',
  //     },
  //   ],
  // },
//   {
//     label: t('Earn'),
//     href: '/farms',
//     icon: 'Earn',
//     items: [
//       {
//         label: t('Farms'),
//         href: '/farms',
//       },
//       {
//         label: t('Pools'),
//         href: '/pools',
//       },
//     ],
//   },
//   {
//     label: t('Win'),
//     href: '/lottery',
//     icon: 'Trophy',
//     items: [
//       {
//         label: t('Lottery'),
//         href: '/lottery',
//       },
//     ],
//  },
    // {
  //  label: t('NFT'),
  //   href: `${nftsBaseUrl}`,
  //   icon: 'Nft',
  //   items: [
  //     {
    //     label: t('Overview'),
    //     href: `${nftsBaseUrl}`,
  //     },
    //   {
    //     label: t('Collections'),
    //     href: `${nftsBaseUrl}/collections`,
    //   },
  //   ],
//   },
  // {
  //   label: 'More',
  //   href: '/info',
  //   icon: 'More',
  //   hideSubNav: true,
  //   items: [
  //     {
  //       label: t('Analytics'),
  //       href: '/info',
  //     },
    //  {
      //     label: t('IFO'),
      //     href: '/ifo',
    //     },
      // {
      //   label: t('Voting'),
      //   href: 'https://snapshot.org/#/endgame-app.eth',
      //   type: DropdownMenuItemType.EXTERNAL_LINK,
      // },
    //  {
      //  type: DropdownMenuItemType.DIVIDER,
  //    },
      //   {
      //     label: t('Leaderboard'),
      //     href: '/teams',
      //   },
  //     {
  //       type: DropdownMenuItemType.DIVIDER,
  //     },
  //     {
  //       label: t('Blog'),
  //       href: 'https://medium.com/@endgame_exchange',
  //       type: DropdownMenuItemType.EXTERNAL_LINK,
  //     },
  //     {
  //       label: t('Docs'),
  //       href: 'https://docs.endgame.app',
  //       type: DropdownMenuItemType.EXTERNAL_LINK,
  //     },
  //   ],
  // },
]

export default config
