import { FooterLinkType } from '@pancakeswap/uikit'
import { ContextApi } from 'contexts/Localization/types'

export const footerLinks: (t: ContextApi['t']) => FooterLinkType[] = (t) => [
  {
    label: t('About'),
    items: [
      {
        label: t('Contact'),
        href: 'https://docs.endgame.app/contact-us',
      },
      {
        label: t('Brand'),
        href: 'https://docs.endgame.app/brand-and-logos',
      },
      {
        label: t('Blog'),
        href: 'https://medium.com/@endgame_exchange',
      },
      {
        label: t('Community'),
        href: 'https://docs.endgame.app/contact-us/communities',
      },
      {
        label: t('Buy DEFI Token'),
        href: 'https://ico.endgame.app',
      },
      {
        label: '—',
      },
      {
        label: t('Online Store'),
        href: 'https://shop.endgame.app/',
        isHighlighted: true,
      },
    ],
  },
  {
    label: t('Help'),
    items: [
      {
        label: t('Customer Support'),
        href: 'https://docs.endgame.app/contact-us/customer-support',
      },
      {
        label: t('Troubleshooting'),
        href: 'https://docs.endgame.app/click-here-for-help/troubleshooting-errors',
      },
      {
        label: t('Guides'),
        href: 'https://docs.endgame.app/',
      },
    ],
  },
  {
    label: t('Developers'),
    items: [
      {
        label: 'Github',
        href: 'https://github.com/',
      },
      {
        label: t('Documentation'),
        href: 'https://docs.endgame.app',
      },
      {
        label: t('Bug Bounty'),
        href: 'https://docs.endgame.app/developers/bug-bounty',
      },
      {
        label: t('Careers'),
        href: 'https://docs.endgame.app/careers/hiring',
      },
    ],
  },
]
